import { ElMessage } from 'element-plus'

export const typeprice = {
    state: () => ({
        typeprices:[],
        activeTypeprices:[],
        countTypeprices:0,
        countAllTypeprices: 0
    }),
    getters:{
        countTypeprices(state){
            return state.countTypeprices
        },
        countAllTypeprices(state){
            return state.countAllTypeprices
        },
        typeprices(state){
            return state.typeprices
        },
    },
    mutations:{
        countTypeprices(state,payload){
            state.countTypeprices = payload
        },
        countAllTypeprices(state,payload){
            state.countAllTypeprices = payload
        },
        typeprices(state,payload){
            state.typeprices = payload
        },
        newTypeprice(state,payload){
            state.typeprices = [payload,...state.typeprices]
            state.countTypeprices += 1
            state.countAllTypeprices += 1
        },
        saveTypeprice(state,payload){
            let index = state.typeprices.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.typeprices[index] = payload
            }
        },
        deleteTypeprice(state,payload){
            state.typeprices = state.typeprices.filter(typeprice => {
                if (typeprice._id == payload) return false
                return typeprice
            })
            state.countTypeprices -= 1
            state.countAllTypeprices -= 1
        }
    },
    actions:{
        async getTypeprices(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'typeprice',search:payload})
            if (res.status == 200){
                console.log(res.data);
                context.commit('loading',false)
                context.commit('typeprices',res.data.typeprices)
                context.commit('countAllTypeprices',res.data.count)
            }
        },
        async getCountTypeprices(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'typeprice/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countTypeprices',res.data)
            }
        },

        async addTypeprice(context,payload){
            let res = await context.dispatch('postAxios',{url:'typeprice',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый цены добавили',
                    type: 'success',
                })
                context.commit('newTypeprice',res.data)
            }
        },

        async changeStatusTypeprice(context,payload){
            let res = await context.dispatch('getAxios',{url:`typeprice/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveTypeprice',res.data)
                ElMessage({
                    message: 'Цены изменили',
                    type: 'success',
                })
            }
        },
        async editTypeprice(context,payload){
            return await context.dispatch('getAxios',{url:`typeprice/${payload}`})
        },
        async saveTypeprice(context,payload){
            let res = await context.dispatch('putAxios',{url:`typeprice/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveTypeprice',res.data)
                ElMessage({
                    message: 'Цены изменили',
                    type: 'success',
                })
            }
        },
        
        async removeTypeprice(context,payload){
            let res = await context.dispatch('deleteAxios',`typeprice/${payload}`)
            if (res.status == 200){
                context.commit('deleteTypeprice',payload)
                ElMessage({
                    message: 'Цены удалили',
                    type: 'success',
                })
            }
        },
    }
}