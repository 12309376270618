import { ElMessage } from 'element-plus'

export const location = {
    state: () => ({
        locations:[],
        lastLocation:{},
    }),
    getters:{
        locations(state){
            return state.locations
        },
        lastLocation(state){
            return state.lastLocation
        },
    },
    mutations:{
        locations(state,payload){
            state.locations = payload
        },
        lastLocation(state,payload){
            state.lastLocation = payload
        },
        deleteLocation(state,payload){
            state.locations = state.locations.filter(location => {
                if (location._id == payload) return false
                return location
            })
        }
    },
    actions:{
        async getLocations(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'location',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('locations',res.data)
            }
        },
        async getLastLocation(context, payload){
            return await context.dispatch('getAxios',{url:`location/last/${payload}` })
        },
        async removeLocation(context,payload){
            let res = await context.dispatch('deleteAxios',`location/${payload}`)
            if (res.status == 200){
                context.commit('deleteLocation',payload)
                ElMessage({
                    message: 'удалили',
                    type: 'success',
                })
            }
        },
    }
}