export const factorSettings = [
  {
      path:'regions',
      icon:'Place',
      name: 'regions',
      title:'Регионы',
      component:() => import(/* webpackChunkName: "direction" */ '../views/factor/settings/AllRegions.vue'),
      meta: { role:['factor'] }
  },
  {
      path:'district',
      icon:'Guide',
      name: 'district',
      title:'Районы',
      component:() => import(/* webpackChunkName: "direction" */ '../views/factor/settings/AllDistricts.vue'),
      meta: { role:['factor'] }
  },
  {
    path:'unit',
    icon:'Paperclip',
    name: 'unit',
    title:'Единицы',
    component:() => import(/* webpackChunkName: "direction" */ '../views/factor/settings/AllUnits.vue'),
    meta: { role:['factor'] }
},
]

export const factorMenu = [
  {
    path:'/subrealisator',
    icon:'user',
    name: 'subrealisator',
    title:'Субреализаторы',
    component:() => import(/* webpackChunkName: "direction" */ '../views/factor/AllSubrealisators.vue'),
    meta: { role:['factor'] }
},
{
    path:'/client',
    icon:'Finished',
    name: 'client',
    title:'Клиенты',
    component:() => import(/* webpackChunkName: "category" */ '../views/factor/AllClients.vue'),
    meta: { role:['factor'] }
},
{
    path:'/typeprice',
    icon:'ScaleToOriginal',
    name: 'typeprice',
    title:'Виды цены',
    component:() => import(/* webpackChunkName: "category" */ '../views/factor/AllTypeprices.vue'),
    meta: { role:['factor'] }
},
{
    path:'/category',
    icon:'Suitcase',
    name: 'category',
    title:'Категории',
    component:() => import(/* webpackChunkName: "category" */ '../views/factor/AllCategory.vue'),
    meta: { role:['factor'] }
},
{
    path:'/product',
    icon:'Goods',
    name: 'produkt',
    title:'Продукты',
    component:() => import(/* webpackChunkName: "category" */ '../views/factor/AllProducts.vue'),
    meta: { role:['factor'] }
},
];

export const factorLinks = [
    {
        path:'/typeproduct/:id',
        icon:'ScaleToOriginal',
        name: 'typeproduct',
        title:'Все виды продукты',
        component:() => import(/* webpackChunkName: "category" */ '../views/factor/AllTypeproduct.vue'),
        meta: { role:['factor',] }
    },
    ...factorMenu,
]