export const superAdminSettings = [
    {
        path:'/factoryadmin',
        icon:'collection',
        name: 'factoryadmin',
        title:'Список цех админы',
        component:() => import(/* webpackChunkName: "direction" */ '../views/superAdmin/AllFactoryAdminList.vue'),
        meta: { role:['superadmin'] }
    },
    
    
]

export const superAdminMenu = [
  {
    path: '/factoryadmin',
    icon: 'copy-document',
    name: 'Цех админы',
    title: 'Цех админы',
    component: () =>
      import(/* webpackChunkName: "boss" */ '../views/superAdmin/AllFactoryAdminList.vue'),
    meta: { role: ['superadmin'] },
  },
  // {
  //   path: '/users',
  //   icon: 'user',
  //   name: 'users',
  //   title: 'Ползователи',
  //   component: () =>
  //     import(/* webpackChunkName: "group" */ '../views/boss/GroupList.vue'),
  //   meta: { role: ['admin', 'rector', 'director'] },
  // },
  
];

export const superAdminLinks = [
    ...superAdminMenu,
    ...superAdminSettings
    // {
        
    //     path:'/settings/subject/:id',
    //     name: 'subjectView',
    //     component:() => import(/* webpackChunkName: "subjectView" */ '../views/boss/SubjectView.vue'),
    //     meta: { role:['admin','rector','director','teacher'] },
    // },
    // {
        
    //     path:'/testproccess/:id',
    //     name: 'testproccessView',
    //     component:() => import(/* webpackChunkName: "testproccessView" */ '../views/boss/testproccess/ShowTestProccess.vue'),
    //     meta: { role:['admin','rector','director'] },
    // },
]