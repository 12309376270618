export const realisatorSettings = [
    {
        path:'/subrealisator/:id',
        name: 'subrealisatora',
        component:() => import( '../views/factor/ViewSubrealisator.vue'),
        meta: { role:['factor', 'realisator'] },
    }
]

export const realisatorMenu = [
  {
    path:'/subrealisator',
    icon:'user',
    name: 'subrealisator',
    title:'Субреализаторы',
    component:() => import(/* webpackChunkName: "direction" */ '../views/factor/AllSubrealisators.vue'),
    meta: { role:['factor', 'realisator'] },
},
{
    path:'/client',
    icon:'Finished',
    name: 'client',
    title:'Клиенты',
    component:() => import(/* webpackChunkName: "category" */ '../views/factor/AllClients.vue'),
    meta: { role:['factor', 'realisator'] }
},

{
    path:'/realtypeprice',
    icon:'ScaleToOriginal',
    name: 'realtypeprice',
    title:'Виды цены',
    component:() => import(/* webpackChunkName: "category" */ '../views/realisator/AllRealTypeprice.vue'),
    meta: { role:['realisator'] }
},
{
    path:'/realproduct',
    icon:'Goods',
    name: 'realproduct',
    title:'Продукты',
    component:() => import(/* webpackChunkName: "category" */ '../views/realisator/AllProduct.vue'),
    meta: { role:['factor', 'realisator'] }
},
];

export const realisatorLinks = [
    {
        path:'/realtypeproduct/:id',
        icon:'ScaleToOriginal',
        name: 'realtypeproduct',
        title:'Виды продукты',
        component:() => import(/* webpackChunkName: "category" */ '../views/realisator/AllRealTypeproduct.vue'),
        meta: { role:['realisator'] }
    },
    
    ...realisatorMenu,
    ...realisatorSettings
]