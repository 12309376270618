<template>
  <div class="home row">
    <div class="col-4">
      <div class="homebox yellow">
        <div class="homebox__left">
          <div class="homebox__title homebox__yellowtitle">Цех админы</div>
          <div class="homebox__text homebox__yellowtitle">
            Количество: {{ countFactoryAdmin }}
          </div>
        </div>
        <div class="homebox__right">
          <div class="homebox__icon">
            <el-icon>
              <OfficeBuilding style="width: 3rem; height: 2rem" />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["countFactoryAdmin"]),
  },
  methods: {
    ...mapActions(["getCountFactoryAdmins"]),
  },
  created() {
    this.getCountFactoryAdmins();
  },
};
</script>

<style></style>
