<template>
  <component :is="user.role" />
</template>

<script>
import { mapGetters } from "vuex";
// import Chart from 'chart.js/auto'
import HomeSuperAdminPage from "./superAdmin/HomeSuperAdmin.vue";
import HomeAdminPage from "./admin/HomePage.vue";
import HomeFactorPage from "./factor/HomeFactor.vue";
import HomeRealisatorPage from "./realisator/HomeRealisator.vue";
export default {
  name: "HomeView",
  components: {
    superadmin: HomeSuperAdminPage,
    admin: HomeAdminPage,
    factor: HomeFactorPage,
    realisator: HomeRealisatorPage,
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="scss">
@import "@/styles/view/home.scss";
</style>
