import { ElMessage } from 'element-plus'

export const subrealisator = {
    state: () => ({
        subrealisators:[],
        activeSubrealisators:[],
        countSubrealisator:0,
        countAllSubrealisator: 0
    }),
    getters:{
        countSubrealisator(state){
            return state.countSubrealisator
        },
        countAllSubrealisator(state){
            return state.countAllSubrealisator
        },
        subrealisators(state){
            return state.subrealisators
        },
        activeSubrealisators(state){
            return state.activeSubrealisators
        },
    },
    mutations:{
        countSubrealisators(state,payload){
            state.countSubrealisator = payload
        },
        countAllSubrealisator(state,payload){
            state.countAllSubrealisator = payload
        },
        subrealisators(state,payload){
            state.subrealisators = payload
        },
        activeSubrealisators(state,payload){
            state.activeSubrealisators = payload
        },
        newSubrealisator(state,payload){
            state.subrealisators = [payload,...state.subrealisators]
            state.countSubrealisator += 1
            state.countAllSubrealisator += 1
        },
        saveSubrealisator(state,payload){
            let index = state.subrealisators.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.subrealisators[index] = payload
            }
        },
        deleteSubrealisator(state,payload){
            state.subrealisators = state.subrealisators.filter(subrealisator => {
                if (subrealisator._id == payload) return false
                return subrealisator
            })
            state.countSubrealisator -= 1
            state.countAllSubrealisator -= 1
        }
    },
    actions:{
        async getSubrealisators(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'subrealisator',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('subrealisators',res.data.subrealisators)
                context.commit('countAllSubrealisator',res.data.count)
            }
        },
        async getActiveSubrealisators(context,){
            let res = await context.dispatch('getAxios',{url:'subrealisator/active'})
            if (res.status == 200){
                context.commit('activeSubrealisators',res.data)
            }
        },
        async getCountSubrealisators(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'subrealisator/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countSubrealisator',res.data)
            }
        },

        async addSubrealisator(context,payload){
            let res = await context.dispatch('postAxios',{url:'subrealisator',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый субреализатор добавил',
                    type: 'success',
                })
                context.commit('newSubrealisator',res.data)
            }
        },

        async changeStatusSubrealisator(context,payload){
            let res = await context.dispatch('getAxios',{url:`subrealisator/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveSubrealisator',res.data)
                ElMessage({
                    message: 'Субреализатор изменили',
                    type: 'success',
                })
            }
        },
        async editSubrealisator(context,payload){
            return await context.dispatch('getAxios',{url:`subrealisator/${payload}`})
        },
        async saveSubrealisator(context,payload){
            let res = await context.dispatch('putAxios',{url:`subrealisator/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveSubrealisator',res.data)
                ElMessage({
                    message: 'Субреализатор изменили',
                    type: 'success',
                })
            }
        },
        
        async removeSubrealisator(context,payload){
            let res = await context.dispatch('deleteAxios',`subrealisator/${payload}`)
            if (res.status == 200){
                context.commit('deleteSubrealisator',payload)
                ElMessage({
                    message: 'Субреализатор удалили',
                    type: 'success',
                })
            }
        },
    }
}