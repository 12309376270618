<template>
  <header class="header">
    <router-link to="/" class="header__logo">
      <!-- <img src="@/assets/logo.png" alt=""/> -->
      &Abeke
    </router-link>
    <div class="header__profile">
      <!-- <button @click="dark">
                <img src="@/assets/icons/sun.svg" alt="">
            </button> -->
      <!-- <router-link
        to="/settings"
        v-if="
          user.role == 'admin' ||
          user.role == 'director' ||
          user.role == 'rektor'
        "
      >
        <img src="@/assets/icons/settings.svg" alt="" />
      </router-link> -->
      <!-- <button v-if="false">
        <img src="@/assets/icons/notification.svg" alt="" />
      </button> -->
      <div class="header__menu">
        <div class="header__name">
          <span v-if="user.role == 'superadmin'">Superadmin</span>
          <span v-if="user.role == 'admin'">Цех админ</span>
          <span v-if="user.role == 'factor'">Цех</span>
          <span v-if="user.role == 'realisator'">Реализатор</span>
          <span>{{ user.login || "Foydalanuvchi" }}</span>
        </div>
        <img
          :src="`${url}/${user.avatar}`"
          v-if="user.avatar"
          alt=""
          class="header__avatar"
        />
        <!-- <img src="@/assets/logo.svg" v-else alt="" class="header__avatar no"> -->
        <div class="dropdown" v-if="user.role != 'superadmin'">
          <div class="dropbox">
            <router-link to="/profile">
              Личные данные
              <el-icon><user /></el-icon>
            </router-link>
            <router-link v-if="user.role == 'factor'" to="/settings/regions">
              Настройка
              <el-icon><operation /></el-icon>
            </router-link>
            <button @click="logout">
              Выйти
              <el-icon><switch-button /></el-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    search: "",
  }),
  computed: {
    ...mapGetters(["url", "user"]),
  },
  methods: {
    ...mapActions(["exit"]),
    dark() {
      this.$store.commit("dark", !this.$store.getters.dark);
    },
    logout() {
      if (confirm("Tizimdan chiqmoqchimisiz?")) {
        this.exit();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/part/header.scss";
</style>
