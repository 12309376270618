import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Maska from 'maska'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import YmapPlugin from 'vue-yandex-maps';
// import { Bar } from 'vue-chartjs'


const settings = {
    apiKey: 'd0c72a86-2cdc-4e81-913a-feb621a308d8',
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1'
  }



const app = createApp(App)
.use(Maska)
.use(ElementPlus)
.use(VueCookies, { expire: '1d'})
.use(store)
.use(router)
.use(YmapPlugin, settings)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')
