import { ElMessage } from 'element-plus'

export const typeproduct = {
    state: () => ({
        typeproducts:[],
        activeTypeproducts:[],
        countTypeproducts:0,
        countAllTypeproducts: 0
    }),
    getters:{
        countTypeproducts(state){
            return state.countTypeproducts
        },
        countAllTypeproducts(state){
            return state.countAllTypeproducts
        },
        typeproducts(state){
            return state.typeproducts
        },
    },
    mutations:{
        countTypeproducts(state,payload){
            state.countTypeproducts = payload
        },
        countAllTypeproducts(state,payload){
            state.countAllTypeproducts = payload
        },
        typeproducts(state,payload){
            state.typeproducts = payload
        },
        newTypeproduct(state,payload){
            state.typeproducts = [payload,...state.typeproducts]
            state.countTypeproducts += 1
            state.countAllTypeproducts += 1
        },
        saveTypeproduct(state,payload){
            let index = state.typeproducts.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.typeproducts[index] = payload
            }
        },
        deleteTypeproduct(state,payload){
            state.typeproducts = state.typeproducts.filter(typeproduct => {
                if (typeproduct._id == payload) return false
                return typeproduct
            })
            state.countTypeproducts -= 1
            state.countAllTypeproducts -= 1
        }
    },
    actions:{
        async getTypeproducts(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'typeproduct',search:payload})
            if (res.status == 200){
                
                context.commit('typeproducts',res.data.typeproducts)
                context.commit('countAllTypeproducts',res.data.count)
                setTimeout(()=> {
                    context.commit('loading',false)
                }, 100)
                
            }
        },
        async getCountTypeproducts(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'typeproduct/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countTypeproducts',res.data)
            }
        },

        async addTypeProduct(context,payload){
            console.log("pay", payload);
            let res = await context.dispatch('postAxios',{url:'typeproduct',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый продукт добавили',
                    type: 'success',
                })
                context.commit('newTypeproduct',res.data)
            }
        },

        async changeStatusTypeproduct(context,payload){
            let res = await context.dispatch('getAxios',{url:`typeproduct/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveTypeproduct',res.data)
                ElMessage({
                    message: 'продукт изменили',
                    type: 'success',
                })
            }
        },
        async editTypeproduct(context,payload){
            return await context.dispatch('getAxios',{url:`typeproduct/${payload}`})
        },
        async saveTypeproduct(context,payload){
            let res = await context.dispatch('putAxios',{url:`typeproduct/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveTypeproduct',res.data)
                ElMessage({
                    message: 'продукт изменили',
                    type: 'success',
                })
            }
        },
        
        async removeTypeproduct(context,payload){
            let res = await context.dispatch('deleteAxios',`typeproduct/${payload}`)
            if (res.status == 200){
                context.commit('deleteTypeproduct',payload)
                ElMessage({
                    message: 'продукт удалили',
                    type: 'success',
                })
            }
        },
    }
}