
import { layoutApp } from './modules/layout'
import { auth } from './modules/auth'
import { helpers } from './modules/helpers'

// SUPERADMIN
import { factoryadmin } from './data/superadmin/factoryadmin'

// ADMIN
import { factor } from './data/admin/factor'
import { realisator } from './data/admin/realisator'

//FACTOR
import { subrealisator } from './data/factor/subrealisator'
import { client } from './data/factor/client'
import { region } from './data/factor/settings/region'
import { district } from './data/factor/settings/district'
import { unit } from './data/factor/settings/unit'
import { typeprice } from './data/factor/typeprice'
import { category } from './data/factor/category'
import { product } from './data/factor/product'
import { typeproduct } from './data/factor/typeproduct'

//REALISATOR
import { location } from './data/realisator/location'
import { realproduct } from './data/realisator/realproduct'


import { user } from './data/user'


export const modulesList = {
    modules: {
        layoutApp,
        auth, helpers,

        // superadmin
        factoryadmin,

        //admin
        factor,
        realisator,

        //factor
        subrealisator,
        client,
        region,
        district,
        unit,
        typeprice,
        category,
        product,
        typeproduct,
        //realisator
        location,
        realproduct,

        // main data
        user
    }
}
