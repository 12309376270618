import { ElMessage } from 'element-plus'

export const factoryadmin = {
    state: () => ({
        factoryadmins:[],
        countFactoryAdmin:0
    }),
    getters:{
        countFactoryAdmin(state){
            return state.countFactoryAdmin
        },
        factoryadmins(state){
            return state.factoryadmins
        },
        activeFactoryAdmins(state){
            return state.factoryadmins.filter(factoryadmin => factoryadmin.status == 0)
        },
        activeSortFactoryAdmins(state){
            let factoryadmins = state.factoryadmins.filter(factoryadmin => factoryadmin.status == 0)
            return factoryadmins.sort()
        }
    },
    mutations:{
        countFactoryAdmins(state,payload){
            state.countFactoryAdmin = payload
        },
        factoryadmins(state,payload){
            state.factoryadmins = payload
        },
        newFactoryAdmin(state,payload){
            state.factoryadmins = [payload,...state.factoryadmins]
            state.countFactoryAdmin += 1
        },
        saveFactoryAdmin(state,payload){
            let index = state.factoryadmins.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.factoryadmins[index] = payload
            }
        },
        deleteFactoryAdmin(state,payload){
            state.factoryadmins = state.factoryadmins.filter(factoryadmin => {
                if (factoryadmin._id == payload) return false
                return factoryadmin
            })
            state.countFactoryAdmin -= 1
        }
    },
    actions:{
        async getFactoryAdmins(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'factoryadmin',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('factoryadmins',res.data)
            }
        },
        async getCountFactoryAdmins(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'factoryadmin/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countFactoryAdmins',res.data)
            }
        },

        async addFactoryAdmin(context,payload){
            let res = await context.dispatch('postAxios',{url:'factoryadmin',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый цех админ добавил',
                    type: 'success',
                })
                context.commit('newFactoryAdmin',res.data)
            }
        },

        async changeStatusFactoryAdmin(context,payload){
            let res = await context.dispatch('getAxios',{url:`factoryadmin/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveFactoryAdmin',res.data)
                ElMessage({
                    message: 'Цех админ статус изменили',
                    type: 'success',
                })
            }
        },
        async editFactoryAdmin(context,payload){
            return await context.dispatch('getAxios',{url:`factoryadmin/${payload}`})
        },
        async saveFactoryAdmin(context,payload){
            let res = await context.dispatch('putAxios',{url:`factoryadmin/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveFactoryAdmin',res.data)
                ElMessage({
                    message: 'Цех админ изменили',
                    type: 'success',
                })
            }
        },
        
        async removeFactoryAdmin(context,payload){
            console.log("pay", payload);
            let res = await context.dispatch('deleteAxios',`factoryadmin/${payload}`)
            if (res.status == 200){
                context.commit('deleteFactoryAdmin',payload)
                ElMessage({
                    message: 'Цех админ удалили',
                    type: 'warning',
                })
            }
        },
    }
}