import { ElMessage } from 'element-plus'

export const category = {
    state: () => ({
        categories:[],
        activeCategories:[],
        countCategories:0,
        countAllCategories: 0
    }),
    getters:{
        countCategories(state){
            return state.countCategories
        },
        countAllCategories(state){
            return state.countAllCategories
        },
        categories(state){
            return state.categories
        },
        activeCategories(state){
            return state.activeCategories
        },
    },
    mutations:{
        countCategories(state,payload){
            state.countCategories = payload
        },
        countAllCategories(state,payload){
            state.countAllCategories = payload
        },
        categories(state,payload){
            state.categories = payload
        },
        activeCategories(state,payload){
            state.activeCategories = payload
        },
        newCategory(state,payload){
            state.categories = [payload,...state.categories]
            state.countCategories += 1
            state.countAllCategories += 1
        },
        saveCategory(state,payload){
            let index = state.categories.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.categories[index] = payload
            }
        },
        deleteCategory(state,payload){
            state.categories = state.categories.filter(category => {
                if (category._id == payload) return false
                return category
            })
            state.countCategories -= 1
            state.countAllCategories -= 1
        }
    },
    actions:{
        async getCategories(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'category',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('categories',res.data.categories)
                context.commit('countAllCategories',res.data.count)
            }
        },
        async getActiveCategories(context,){
            let res = await context.dispatch('getAxios',{url:'category/active'})
            if (res.status == 200){
                context.commit('activeCategories',res.data)
            }
        },
        async getCountCategories(context,payload){
            let res = await context.dispatch('getAxios',{url:'category/count',search:payload})
            if (res.status == 200){
                context.commit('countCategories',res.data)
            }
        },

        async addCategory(context,payload){
            let res = await context.dispatch('postAxios',{url:'category',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый категория добавили',
                    type: 'success',
                })
                context.commit('newCategory',res.data)
            }
        },

        async changeStatusCategory(context,payload){
            let res = await context.dispatch('getAxios',{url:`category/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveCategory',res.data)
                ElMessage({
                    message: 'Категория изменили',
                    type: 'success',
                })
            }
        },
        async editCategory(context,payload){
            return await context.dispatch('getAxios',{url:`category/${payload}`})
        },
        async saveCategory(context,payload){
            let res = await context.dispatch('putAxios',{url:`category/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveCategory',res.data)
                ElMessage({
                    message: 'Категория изменили',
                    type: 'success',
                })
            }
        },
        
        async removeCategory(context,payload){
            let res = await context.dispatch('deleteAxios',`category/${payload}`)
            if (res.status == 200){
                context.commit('deleteCategory',payload)
                ElMessage({
                    message: 'Категория удалили',
                    type: 'success',
                })
            }
        },
    }
}