import { ElMessage } from 'element-plus'

export const factor = {
    state: () => ({
        factors:[],
        countFactor:0,
        countAllFactor: 0
    }),
    getters:{
        countFactor(state){
            return state.countFactor
        },
        countAllFactor(state){
            return state.countAllFactor
        },
        factors(state){
            return state.factors
        },
        activeFactors(state){
            return state.factors.filter(factor => factor.status == 0)
        },
        activeSortFactors(state){
            let factors = state.factors.filter(factor => factor.status == 0)
            return factors.sort()
        }
    },
    mutations:{
        countFactors(state,payload){
            state.countFactor = payload
        },
        countAllFactor(state,payload){
            state.countAllFactor = payload
        },
        factors(state,payload){
            state.factors = payload
        },
        newFactors(state,payload){
            state.factors = [payload,...state.factors]
            state.countFactor += 1
            state.countAllFactor += 1
        },
        saveFactor(state,payload){
            let index = state.factors.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.factors[index] = payload
            }
        },
        deleteFactor(state,payload){
            state.factors = state.factors.filter(factor => {
                if (factor._id == payload) return false
                return factor
            })
            state.countFactor -= 1
            state.countAllFactor -= 1
        }
    },
    actions:{
        async getFactors(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'factory',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('factors',res.data.factors)
                context.commit('countAllFactor',res.data.count)
            }
        },
        async getCountFactors(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'factory/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countFactors',res.data)
            }
        },

        async addFactor(context,payload){
            let res = await context.dispatch('postAxios',{url:'factory',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый цех добавил',
                    type: 'success',
                })
                context.commit('newFactors',res.data)
            }
        },

        async changeStatusFactor(context,payload){
            let res = await context.dispatch('getAxios',{url:`factory/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveFactor',res.data)
                ElMessage({
                    message: 'Цех изменили',
                    type: 'success',
                })
            }
        },
        async editFactor(context,payload){
            return await context.dispatch('getAxios',{url:`factory/${payload}`})
        },
        async saveFactor(context,payload){
            let res = await context.dispatch('putAxios',{url:`factory/${payload._id}`,data: payload})
            if (res.status == 200){
                console.log("savefactor",res.data);
                context.commit('saveFactor',res.data)
                ElMessage({
                    message: 'Цех изменили',
                    type: 'success',
                })
            }
        },
        
        async removeFactor(context,payload){
            let res = await context.dispatch('deleteAxios',`factory/${payload}`)
            if (res.status == 200){
                context.commit('deleteFactor',payload)
                ElMessage({
                    message: 'Цех удалили',
                    type: 'success',
                })
            }
        },
    }
}