
export const realproduct = {
    state: () => ({
        realproducts:[],
        fortypeproduct:[],
        countRealProducts:0,
        // countAllProducts: 0
    }),
    getters:{
        // countProducts(state){
        //     return state.countProducts
        // },
        countRealProducts(state){
            return state.countRealProducts
        },
        realproducts(state){
            return state.realproducts
        },
        fortypeproduct(state){
            return state.fortypeproduct
        },
    },
    mutations:{
        // countProducts(state,payload){
        //     state.countProducts = payload
        // },
        countRealProducts(state,payload){
            state.countRealProducts = payload
        },
        realproducts(state,payload){
            state.realproducts = payload
        },
        fortypeproduct(state,payload){
            state.fortypeproduct = payload
        },
    },
    actions:{
        async getRealProducts(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'product/realtype',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('realproducts',res.data)
                let count = 0
                res.data.forEach(item => {
                    count += item.count
                });
                context.commit('countRealProducts',count)
            }
        },
        async getRealTypeProducts(context, payload){
            let res = await context.dispatch('getAxios',{url:'product/fortypeproduct', search:payload})
            if (res.status == 200){
                context.commit('fortypeproduct',res.data)
            }
        },
        // async getCountProducts(context,payload){
        //     let res = await context.dispatch('getAxios',{url:'product/count',search:payload})
        //     if (res.status == 200){
        //         context.commit('countProducts',res.data)
        //     }
        // },

        
    }
}