<template>
  <div class="home">
    <div class="row">
      <div class="col-4">
        <div class="homebox green">
          <div class="homebox__left">
            <div class="homebox__title">Цехы</div>
            <div class="homebox__text">Количество: {{ countFactor }}</div>
          </div>
          <div class="homebox__right">
            <div class="homebox__icon">
              <el-icon >
                <OfficeBuilding style="width: 3rem; height: 2rem" />
              </el-icon>
            </div>
          </div>
            
        </div>
      </div>
      <div class="col-4">
        <div class="homebox yellow">
          <div class="homebox__left">
            <div class="homebox__title homebox__yellowtitle">Реализаторы</div>
            <div class="homebox__text homebox__yellowtitle">Количество: {{ countRealisator }}</div>
          </div>
          <div class="homebox__right">
            <div class="homebox__icon">
              <el-icon>
                <User />
              </el-icon>
            </div>
          </div>
            
        </div>
      </div>
     
    </div>
    
    <div class="row">
      <div class="col-4">
        <Bar
          id="my-chart-id"
          :options="chartOptions"
          :data="chartData"
        />
      </div>
      <div class="col-4">
        <Bar
          id="my-chart-id"
          :options="chartOptions"
          :data="chartData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  data() {
    return {
      chartData: {
        labels: [ 'Январ', 'Феврал', 'Март', 'Апрел', 'Март', 'Июн', 'Июл', 'Август', 'Сентабр', 'Октабр','Ноябр', 'Декабр'],
        datasets: [ 
          { 
            data: [40, 20, 12, 50, 23, 34, 45, 12, 34, 56, 23, 19],
            backgroundColor: ['#0A5F59', '#FDCF6F', '#0A5F59', '#FDCF6F'] 
          },
        ]
      },
      chartOptions: {
        responsive: true
      }
    }
  },
  computed:{
      ...mapGetters([
          'countFactor',
          'countRealisator',
      ])
  },
  methods:{
    ...mapActions([
        'getCountFactors',
        'getCountRealisators',
      ]),
  },
  created(){
      this.getCountRealisators(),
      this.getCountFactors()
  }
}
</script>

<style>

</style>