<template>
  <component :is="layout" />
</template>

<script>
import SuperAdminLayout from "@/layout/superAdminLayout.vue";
import AdminLayout from "@/layout/adminLayout.vue";
import FactorLayout from "@/layout/factorLayout.vue";
import RealisatorLayout from "@/layout/realisatorLayout.vue";
import ClientLayout from "@/layout/clientLayout.vue";
import AuthLayout from "@/layout/authLayout.vue";

export default {
  components: {
    "superadmin-layout": SuperAdminLayout,
    "admin-layout": AdminLayout,
    "factor-layout": FactorLayout,
    "realisator-layout": RealisatorLayout,
    "client-layout": ClientLayout,
    "auth-layout": AuthLayout,
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
  },
  methods: {
    checkUser() {
      if (this.$cookies.isKey("quite-user")) {
        this.$store.dispatch("checkUser", this.$cookies.get("quite-user"));
      } else {
        this.$router.push("/login");
      }
    },
  },
  watch: {
    $route(to) {
      if (to.name == "login") {
        this.$store.dispatch("setLayout", "auth-layout");
      } else {
        this.checkUser();
      }
    },
  },
  mounted() {
    this.checkUser();
    document.title = "Abeke";
  },
};
</script>

<style lang="scss">
@import "./styles/reset.scss";
@import "./styles/grid.scss";
@import "./styles/main.scss";
@import "./styles/lib/class.scss";
</style>
