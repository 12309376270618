export const adminSettings = [
    {
        path:'/factory',
        icon:'OfficeBuilding',
        name: 'factor',
        title:'Цехы',
        component:() => import(/* webpackChunkName: "direction" */ '../views/admin/AllFactors.vue'),
        meta: { role:['admin'] }
    },
    {
        path:'/realisator',
        icon:'user',
        name: 'realisator',
        title:'Реализаторы',
        component:() => import(/* webpackChunkName: "category" */ '../views/admin/AllRealisators.vue'),
        meta: { role:['admin'] }
    },
]

export const adminMenu = [
  {
    path:'/factory',
    icon:'OfficeBuilding',
    name: 'factor',
    title:'Цехы',
    component:() => import(/* webpackChunkName: "direction" */ '../views/admin/AllFactors.vue'),
    meta: { role:['admin'] }
  },
  {
    path:'/realisator',
    icon:'user',
    name: 'realisator',
    title:'Реализаторы',
    component:() => import(/* webpackChunkName: "category" */ '../views/admin/AllRealisators.vue'),
    meta: { role:['admin'] }
  },
];

export const adminLinks = [
    ...adminMenu,
    ...adminSettings
]