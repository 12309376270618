import { ElMessage } from 'element-plus'

export const client = {
    state: () => ({
        clients:[],
        activeClients:[],
        countClients:0,
        countAllClients: 0
    }),
    getters:{
        countClients(state){
            return state.countClients
        },
        countAllClients(state){
            return state.countAllClients
        },
        clients(state){
            return state.clients
        },
        activeClients(state){
            return state.activeClients
        },
    },
    mutations:{
        countClients(state,payload){
            state.countClients = payload
        },
        countAllClients(state,payload){
            state.countAllClients = payload
        },
        clients (state,payload){
            state.clients = payload
        },
        activeClients(state,payload){
            state.activeClients = payload
        },
        newClient(state,payload){
            state.clients = [payload,...state.clients]
            state.countClients += 1
            state.countAllClients += 1
        },
        saveClient(state,payload){
            let index = state.clients.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.clients[index] = payload
            }
        },
        deleteClient(state,payload){
            state.clients = state.clients.filter(client => {
                if (client._id == payload) return false
                return client
            })
            state.countClients -= 1
            state.countAllClients -= 1
        }
    },
    actions:{
        async getClients(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'client',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('clients',res.data.clients)
                context.commit('countAllClients',res.data.count)
            }
        },
        async getCountClients(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'client/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countClients',res.data)
            }
        },
        async getActiveClients(context,){
            let res = await context.dispatch('getAxios',{url:'client/active'})
            if (res.status == 200){
                context.commit('activeClients',res.data)
            }
        },
        async addClient(context,payload){
            let res = await context.dispatch('postAxios',{url:'client',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый клиент добавил',
                    type: 'success',
                })
                context.commit('newClient',res.data)
            }
        },

        async changeStatusClient(context,payload){
            let res = await context.dispatch('getAxios',{url:`client/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveClient',res.data)
                ElMessage({
                    message: 'Клиент изменили',
                    type: 'success',
                })
            }
        },
        async editClient(context,payload){
            return await context.dispatch('getAxios',{url:`client/${payload}`})
        },
        async saveClient(context,payload){
            let res = await context.dispatch('putAxios',{url:`client/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveClient',res.data)
                ElMessage({
                    message: 'Клиент изменили',
                    type: 'success',
                })
            }
        },
        
        async removeClient(context,payload){
            let res = await context.dispatch('deleteAxios',`client/${payload}`)
            if (res.status == 200){
                context.commit('deleteClient',payload)
                ElMessage({
                    message: 'Клиент удалили',
                    type: 'success',
                })
            }
        },
    }
}