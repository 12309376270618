import { ElMessage } from 'element-plus'


export const district = {
    state: () => ({
        districts:[],
        activeDistricts:[],
        countDistrict:0,
        countAllDistrict: 0
    }),
    getters:{
        countDistrict(state){
            return state.countAllDistrict
        },
        countAllDistrict(state){
            return state.countAllDistrict
        },
        districts(state){
            return state.districts
        },
        activeDistricts(state){
            return state.activeDistricts
        },
    },
    mutations:{
        countDistrict(state,payload){
            state.countDistrict = payload
        },
        countAllDistrict(state,payload){
            state.countAllDistrict = payload
        },
        districts(state,payload){
            state.districts = payload
        },
        activeDistricts(state,payload){
            state.activeDistricts = payload
        },
        newDistrict(state,payload){
            state.districts = [payload,...state.districts]
            state.countDistrict += 1
            state.countAllDistrict += 1
        },
        saveDistrict(state,payload){
            let index = state.districts.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.districts[index] = payload
            }
        },
        deleteDistrict(state,payload){
            state.districts = state.districts.filter(district => {
                if (district._id == payload) return false
                return district
            })
            state.countDistrict -= 1
            state.countAllDistrict -= 1
        }
    },
    actions:{
        async getDistricts(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'district',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('districts',res.data.districts)
                context.commit('countAllDistrict',res.data.count)
            }
        },
        async getActiveDistricts(context, payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'district/active', search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('activeDistricts',res.data)
            }
        },
        async getCountDistricts(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'district/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countDistrict',res.data)
            }
        },

        async addDistrict(context,payload){
            let res = await context.dispatch('postAxios',{url:'district',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый район добавил',
                    type: 'success',
                })
                context.commit('newDistrict',res.data)
            }
        },

        async changeStatusDistrict(context,payload){
            let res = await context.dispatch('getAxios',{url:`district/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveDistrict',res.data)
                ElMessage({
                    message: 'Район изменили',
                    type: 'success',
                })
            }
        },
        async editDistrict(context,payload){
            return await context.dispatch('getAxios',{url:`district/${payload}`})
        },
        async saveDistrict(context,payload){
            let res = await context.dispatch('putAxios',{url:`district/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveDistrict',res.data)
                ElMessage({
                    message: 'Район изменили',
                    type: 'success',
                })
            }
        },
        
        async removeDistrict(context,payload){
            let res = await context.dispatch('deleteAxios',`district/${payload}`)
            if (res.status == 200){
                context.commit('deleteDistrict',payload)
                ElMessage({
                    message: 'Район удалили',
                    type: 'success',
                })
            }
        },
    }
}