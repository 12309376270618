import { ElMessage } from 'element-plus'

export const unit = {
    state: () => ({
        units:[],
        activeUnits:[],
        countUnits:0,
        countAllUnits: 0
    }),
    getters:{
        countUnits(state){
            return state.countUnits
        },
        countAllUnits(state){
            return state.countAllUnits
        },
        units(state){
            return state.units
        },
        activeUnits(state){
            return state.activeUnits
        },
    },
    mutations:{
        countUnits(state,payload){
            state.countUnits = payload
        },
        countAllUnits(state,payload){
            state.countAllUnits = payload
        },
        units(state,payload){
            state.units = payload
        },
        activeUnits(state,payload){
            state.activeUnits = payload
        },
        newUnit(state,payload){
            state.units = [payload,...state.units]
            state.countUnits += 1
            state.countAllUnits += 1
        },
        saveUnit(state,payload){
            let index = state.units.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.units[index] = payload
            }
        },
        deleteUnit(state,payload){
            state.units = state.units.filter(unit => {
                if (unit._id == payload) return false
                return unit
            })
            state.countUnits -= 1
            state.countAllUnits -= 1
        }
    },
    actions:{
        async getUnits(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'unit',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('units',res.data.units)
                context.commit('countAllUnits',res.data.count)
            }
        },
        async getActiveUnits(context){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'unit/active'})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('activeUnits',res.data)
            }
        },
        async getCountUnits(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'unit/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countUnits',res.data)
            }
        },
        async addUnit(context,payload){
            let res = await context.dispatch('postAxios',{url:'unit',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый единица добавил',
                    type: 'success',
                })
                context.commit('newUnit',res.data)
            }
        },
        async changeStatusUnit(context,payload){
            let res = await context.dispatch('getAxios',{url:`unit/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveUnit',res.data)
                ElMessage({
                    message: 'Единица изменили',
                    type: 'success',
                })
            }
        },
        async editUnit(context,payload){
            return await context.dispatch('getAxios',{url:`unit/${payload}`})
        },
        async saveUnit(context,payload){
            let res = await context.dispatch('putAxios',{url:`unit/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveUnit',res.data)
                ElMessage({
                    message: 'Единица изменили',
                    type: 'success',
                })
            }
        },
        
        async removeUnit(context,payload){
            let res = await context.dispatch('deleteAxios',`unit/${payload}`)
            if (res.status == 200){
                context.commit('deleteUnit',payload)
                ElMessage({
                    message: 'Единица удалили',
                    type: 'success',
                })
            }
        },
    }
}