<template>
  <div class="home row">
    <div class="col-4">
      <div class="homebox green">
        <div class="homebox__left">
          <div class="homebox__title">Цехы</div>
          <div class="homebox__text">Количество: {{ countFactor }}</div>
        </div>
        <div class="homebox__right">
          <div class="homebox__icon">
            <el-icon>
              <OfficeBuilding style="width: 3rem; height: 2rem" />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="homebox yellow">
        <div class="homebox__left">
          <div class="homebox__title homebox__yellowtitle">Реализаторы</div>
          <div class="homebox__text homebox__yellowtitle">
            Количество: {{ countRealisator }}
          </div>
        </div>
        <div class="homebox__right">
          <div class="homebox__icon">
            <el-icon>
              <User />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["countFactor", "countRealisator"]),
  },
  methods: {
    ...mapActions(["getCountFactors", "getCountRealisators"]),
  },
  created() {
    this.getCountRealisators(), this.getCountFactors();
  },
};
</script>

<style></style>
