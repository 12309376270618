export const clientSettings = [
    
  ]
  
  export const clientMenu = [
//     {
//       path:'/order',
//       icon:'Suitcase',
//       name: 'order',
//       title:'Заказы',
//       component:() => import(/* webpackChunkName: "direction" */ '../views/factor/AllOrders.vue'),
//       meta: { role:['client'] }
//   },
  {
      path:'/product',
      icon:'Goods',
      name: 'produkt',
      title:'Продукты',
      component:() => import(/* webpackChunkName: "category" */ '../views/client/ClientProducts.vue'),
      meta: { role:['client'] }
  },
  ];
  
  export const clientLinks = [
      
      ...clientMenu,
  ]