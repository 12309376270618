import { ElMessage } from 'element-plus'

export const realisator = {
    state: () => ({
        realisators:[],
        activeRealisators: [],
        countRealisator:0,
        countAllRealisator: 0
    }),
    getters:{
        countRealisator(state){
            return state.countRealisator
        },
        countAllRealisator(state){
            return state.countAllRealisator
        },
        realisators(state){
            return state.realisators
        },
        activeRealisators(state){
            return state.activeRealisators
        },
    },
    mutations:{
        countRealisators(state,payload){
            state.countRealisator = payload
        },
        countAllRealisator(state,payload){
            state.countAllRealisator = payload
        },
        realisators(state,payload){
            state.realisators = payload
        },
        activeRealisators(state,payload){
            state.activeRealisators = payload
        },
        newRealisator(state,payload){
            state.realisators = [payload,...state.realisators]
            state.countRealisator += 1
            state.countAllRealisator += 1
        },
        saveRealisator(state,payload){
            let index = state.realisators.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.realisators[index] = payload
            }
        },
        deleteRealisator(state,payload){
            state.realisators = state.realisators.filter(realisator => {
                if (realisator._id == payload) return false
                return realisator
            })
            state.countRealisator -= 1
            state.countAllRealisator -= 1
        }
    },
    actions:{
        async getRealisators(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'realisator',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('realisators',res.data.realisators)
                context.commit('countAllRealisator',res.data.count)
            }
        },
        async getCountRealisators(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'realisator/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countRealisators',res.data)
            }
        },
        async getActiveRealisators(context,){
            let res = await context.dispatch('getAxios',{url:'realisator/active'})
            if (res.status == 200){
                context.commit('activeRealisators',res.data)
            }
        },
        async addRealisator(context,payload){
            let res = await context.dispatch('postAxios',{url:'realisator',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый реализатор добавил',
                    type: 'success',
                })
                context.commit('newRealisator',res.data)
            }
        },

        async changeStatusRealisator(context,payload){
            let res = await context.dispatch('getAxios',{url:`realisator/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveRealisator',res.data)
                ElMessage({
                    message: 'Реализатор изменили',
                    type: 'success',
                })
            }
        },
        async editRealisator(context,payload){
            return await context.dispatch('getAxios',{url:`realisator/${payload}`})
        },
        async saveRealisator(context,payload){
            let res = await context.dispatch('putAxios',{url:`realisator/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveRealisator',res.data)
                ElMessage({
                    message: 'Реализатор изменили',
                    type: 'success',
                })
            }
        },
        
        async removeRealisator(context,payload){
            let res = await context.dispatch('deleteAxios',`realisator/${payload}`)
            if (res.status == 200){
                context.commit('deleteRealisator',payload)
                ElMessage({
                    message: 'Реализатор удалили',
                    type: 'success',
                })
            }
        },
    }
}