import { ElMessage } from 'element-plus'

export const region = {
    state: () => ({
        regions:[],
        activeRegions:[],
        countRegion:0,
        countAllRegion: 0
    }),
    getters:{
        countRegion(state){
            return state.countRegion
        },
        countAllRegion(state){
            return state.countAllRegion
        },
        regions(state){
            return state.regions
        },
        activeRegions(state){
            return state.activeRegions
        },
    },
    mutations:{
        countRegion(state,payload){
            state.countRegion = payload
        },
        countAllRegion(state,payload){
            state.countAllRegion = payload
        },
        regions(state,payload){
            state.regions = payload
        },
        activeRegions(state,payload){
            state.activeRegions = payload
        },
        newRegion(state,payload){
            state.regions = [payload,...state.regions]
            state.countRegion += 1
            state.countAllRegion += 1
        },
        saveRegion(state,payload){
            let index = state.regions.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.regions[index] = payload
            }
        },
        deleteRegion(state,payload){
            state.regions = state.regions.filter(region => {
                if (region._id == payload) return false
                return region
            })
            state.countRegion -= 1
            state.countAllRegion -= 1
        }
    },
    actions:{
        async getRegions(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'region',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('regions',res.data.regions)
                context.commit('countAllRegion',res.data.count)
            }
        },
        async getActiveRegions(context){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'region/active'})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('activeRegions',res.data)
            }
        },
        async getCountRegions(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'region/count',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('countRegion',res.data)
            }
        },

        async addRegion(context,payload){
            let res = await context.dispatch('postAxios',{url:'region',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый регион добавил',
                    type: 'success',
                })
                context.commit('newRegion',res.data)
            }
        },

        async changeStatusRegion(context,payload){
            let res = await context.dispatch('getAxios',{url:`region/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveRegion',res.data)
                ElMessage({
                    message: 'Регион изменили',
                    type: 'success',
                })
            }
        },
        async editRegion(context,payload){
            return await context.dispatch('getAxios',{url:`region/${payload}`})
        },
        async saveRegion(context,payload){
            let res = await context.dispatch('putAxios',{url:`region/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveRegion',res.data)
                ElMessage({
                    message: 'Регион изменили',
                    type: 'success',
                })
            }
        },
        
        async removeRegion(context,payload){
            let res = await context.dispatch('deleteAxios',`region/${payload}`)
            if (res.status == 200){
                context.commit('deleteRegion',payload)
                ElMessage({
                    message: 'Регион удалили',
                    type: 'success',
                })
            }
        },
    }
}