import { createStore } from 'vuex'

import { modulesList } from './modules'

export default createStore({
  state: {
    
    url: 'https://abeke-back.bookblogs.uz',
    
    
    months:['Январ','Феврал','Март','Апрел','Май','Июн','Июл','Август','Сентабр','Октабр','Ноябр','Декабр'],
    loading:false,
    dark:false,
    status: ['Faol','Faol emas'],
    weekdays:['Dushanba','Seshanba','Chorshanba','Payshanba','Juma','Shanba']
  },
  getters: {
    dark(state){
      return state.dark
    },
    weekdays(state){
      return state.weekdays
    },
    status(state){
      return state.status
    },
    loading(state){
      return state.loading
    },
    url(state){
      return state.url
    },
    uri(state){
      return state.uri
    },
    months(state){
      return state.months
    }
  },
  mutations:{
    dark(state,payload){
      state.dark = payload
    },
    loading(state,payload){
      state.loading = payload
    }
  },
  modules: {
    modulesList
  }
})
