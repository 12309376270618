import { ElMessage } from 'element-plus'

export const product = {
    state: () => ({
        products:[],
        activeProducts:[],
        countProducts:0,
        countAllProducts: 0
    }),
    getters:{
        countProducts(state){
            return state.countProducts
        },
        countAllProducts(state){
            return state.countAllProducts
        },
        products(state){
            return state.products
        },
        activeProducts(state){
            return state.activeProducts
        },
    },
    mutations:{
        countProducts(state,payload){
            state.countProducts = payload
        },
        countAllProducts(state,payload){
            state.countAllProducts = payload
        },
        products(state,payload){
            state.products = payload
        },
        newProduct(state,payload){
            state.products = [payload,...state.products]
            state.countProducts += 1
            state.countAllProducts += 1
        },
        activeProducts(state,payload){
            state.activeProducts = payload
        },
        saveProduct(state,payload){
            let index = state.products.findIndex(dep => dep._id == payload._id)
            if (index !==-1){
                state.products[index] = payload
            }
        },
        deleteProduct(state,payload){
            state.products = state.products.filter(product => {
                if (product._id == payload) return false
                return product
            })
            state.countProducts -= 1
            state.countAllProducts -= 1
        }
    },
    actions:{
        async getProducts(context,payload){
            context.commit('loading',true)
            let res = await context.dispatch('getAxios',{url:'product',search:payload})
            if (res.status == 200){
                context.commit('loading',false)
                context.commit('products',res.data.products)
                context.commit('countAllProducts',res.data.count)
            }
        },
        async getCountProducts(context,payload){
            let res = await context.dispatch('getAxios',{url:'product/count',search:payload})
            if (res.status == 200){
                context.commit('countProducts',res.data)
            }
        },

        async addProduct(context,payload){
            let res = await context.dispatch('postAxios',{url:'product',data: payload})
            if (res.status == 201) {
                ElMessage({
                    message: 'Новый продукт добавили',
                    type: 'success',
                })
                context.commit('newProduct',res.data)
            }
        },

        async changeStatusProduct(context,payload){
            let res = await context.dispatch('getAxios',{url:`product/change/${payload._id}/${payload.status}`})
            if (res.status == 200) {
                context.commit('saveProduct',res.data)
                ElMessage({
                    message: 'Продукт изменили',
                    type: 'success',
                })
            }
        },
        async getActiveProducts(context, payload){
            let res = await context.dispatch('getAxios',{url:'product/active', search:payload})
            if (res.status == 200){
                context.commit('activeProducts',res.data)
            }
        },
        async editProduct(context,payload){
            return await context.dispatch('getAxios',{url:`product/${payload}`})
        },
        async saveProduct(context,payload){
            let res = await context.dispatch('putAxios',{url:`product/${payload._id}`,data: payload})
            if (res.status == 200){
                context.commit('saveProduct',res.data)
                ElMessage({
                    message: 'Продукт изменили',
                    type: 'success',
                })
            }
        },
        async removeProduct(context,payload){
            let res = await context.dispatch('deleteAxios',`product/${payload}`)
            if (res.status == 200){
                context.commit('deleteProduct',payload)
                ElMessage({
                    message: 'Продукт удалили',
                    type: 'success',
                })
            }
        },
        async deleteProductPhoto({ dispatch }, payload) {
            return dispatch('postAxios', {
                url: 'product/delphoto',
                data: payload,
            });
        },
    }
}